import { useEffect } from 'react'
import SVGLogo from './Logo'
import { Link } from "react-router-dom";
import Button from './Button'

function PrivacyPolicy() {
     useEffect(() => {
        const head = document.querySelector("head");
        const script = document.createElement("script");
      
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7881143807123640";
        script.async = true;
        script.crossOrigin = "anonymous"
      
        head.appendChild(script);
      
        // return () => {
        //   head.removeChild(script);
        // }
      }, []);

    return (
        <>
            <div className="meanings">
                <SVGLogo />
                <h1 className="home__margin-20">Tarot Realms of Insight</h1>
                <h2 className="home__margin-20">Page not found</h2>
                <h3>404 page</h3>
                <p className="home__margin-20">The page you were looking for is not found on the site.</p>

                <div className="home__margin-20">
                    <Link to="/" className="home__modal-links meaninigs__link"><Button>Return Home</Button></Link>
                </div>
            </div>
        </>
    );
}

export default PrivacyPolicy;
