import './Board.css'
import Deck from './Deck.jsx';
import { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'
import Modal from './Modal'
import Button from './Button'

function Board() {
    const location = useLocation();
    const { spread } = location.state ?? false;
    
    const [numCards, setNumCards] = useState(null)

    useEffect(() => {
        if (spread === 'celticCross') {
            setNumCards(10)
        } else if (spread === 'threeCards') {
            setNumCards(3)
        } else if (spread === 'drawOne') {
            setNumCards(1)
        }
    }, [spread, numCards])

    useEffect(() => {
        const head = document.querySelector("head");
        const script = document.createElement("script");
      
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7881143807123640";
        script.async = true;
        script.crossOrigin = "anonymous"
      
        head.appendChild(script);
      
        // return () => {
        //   head.removeChild(script);
        // }
      }, []);
    
      if (!numCards) {
        return (
        <section className="board">
                <Modal reveal={true} timeout={0}>
                    <h3 className="home__modal-title">Please select a Spread</h3>
                    <div className="home__modal-spreads">
                        <Link to={`/board`} className="home__modal-links" onClick={() => setNumCards(1)} aria-label="Play the Draw One Card">One Card</Link>
                        <Link to={`/board`} className="home__modal-links" onClick={() => setNumCards(3)} aria-label="Play the Three Card Spread">Three Cards</Link>
                        <Link to={`/board`} className="home__modal-links" onClick={() => setNumCards(10)} aria-label="Play the Celtic Cross Spread">Celtic Cross</Link>
                    </div>
                    <div className="home__margin-20">
                        <Link to="/" className="home__modal-links meaninigs__link"><Button>Return Home</Button></Link>
                    </div>
                </Modal>
            <Deck numberOfSelectedCards={0}/>
        </section>)
      } else {
          return (
              <section className="board">
                  <Deck numberOfSelectedCards={numCards}/>
              </section>
          );
      }
      }

export default Board;
