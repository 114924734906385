import { useEffect } from 'react'
import SVGLogo from './Logo'
import { Link } from "react-router-dom";
import Button from './Button'

function PrivacyPolicy() {
     useEffect(() => {
        const head = document.querySelector("head");
        const script = document.createElement("script");
      
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7881143807123640";
        script.async = true;
        script.crossOrigin = "anonymous"
      
        head.appendChild(script);
      
        // return () => {
        //   head.removeChild(script);
        // }
      }, []);

    return (
        <>
            <div className="meanings">
                <SVGLogo />
                <h1 className="home__margin-20">Tarot Realms of Insight</h1>
                <h2>Privacy Policy</h2>
                <p className="home__margin-20">Welcome to Tarot Realms of Insight (“we”, “our”, “us”). Your privacy is important to us. This Privacy Policy explains how we handle your personal information when you visit and interact with our website.</p>
                <h2>Information We Collect</h2>
                <p className="home__margin-20">While we do not directly collect any personal data, third-party services like Google AdSense may collect information through cookies and similar technologies to serve personalized ads. This information may include:</p>
                <ul className="home__margin-20">
                    <li>IP addresses</li>
                    <li>Browser type and settings</li>
                    <li>Device type and settings</li>
                    <li>Information about your interactions with ads</li>
                </ul>
                <h2>Use of Cookies</h2>
                <p className="home__margin-20">Our website uses cookies through Google AdSense to:</p>
                <ul>
                    <li>Serve personalized ads based on your interests</li>
                    <li>Track and report website traffic and ad performance</li>
                </ul>
                <p className="home__margin-20">You can choose to disable cookies through your browser settings or manage your preferences via the [AdSense Consent Message] displayed on our website.</p>
                <h2>Third-Party Links</h2>
                <p className="home__margin-20">Our website may contain links to third-party websites. We do not control these websites and are not responsible for their content or privacy practices. We encourage you to review their privacy policies.</p>
                <h2>Changes to This Privacy Policy</h2>
                <p className="home__margin-20">We may update this Privacy Policy from time to time. Any changes will be posted on this page.</p>

                <div className="home__margin-20">
                    <Link to="/" className="home__modal-links meaninigs__link"><Button>Return Home</Button></Link>
                </div>
            </div>
        </>
    );
}

export default PrivacyPolicy;
